import React, { useState, useEffect } from "react";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext.js";

import "../App.css";

const MenuSection = ({ bannerRef }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationClickCounter, setPaginationClickCounter] = useState(0);
  const itemsPerPage = 1;
  const { language } = useLanguage();
  const { menuSection } = translations[language];

  useEffect(() => {
    fetch("Api.json")
      .then((response) => response.json())
      .then((data) => {
        const menuData = data.menu_items;
        console.log(data);
        setMenuItems(menuData);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const menuItems = document.querySelectorAll(".menu-section-item");
      const windowHeight = window.innerHeight;

      menuItems.forEach((item) => {
        const itemTop = item.getBoundingClientRect().top;

        if (itemTop < windowHeight * 0.8) {
          item.classList.add("fade-in");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePaginationButtonClick = () => {
    setPaginationClickCounter((prevCounter) => prevCounter + 1);
  };

  useEffect(() => {
    if (bannerRef && bannerRef.current && paginationClickCounter > 0) {
      bannerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage, menuItems, bannerRef, paginationClickCounter]);

  return (
    <div className="menu-section">
      {menuItems
        .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
        .map((category, index) => (
          <div
            className="menu-section-category"
            key={index}
            id={`category-${category.id}`}
          >
            <h1>{menuSection[category.category].toUpperCase()}</h1>
            {category.items.map((item, itemIndex) => (
              <div
                className={`menu-section-item ${
                  itemIndex === 0 ? "fade-in" : ""
                }`}
                key={item.id}
              >
                <img
                  loading="lazy"
                  src={item.image}
                  alt={item.name[language]}
                />
                <p id="name-paragraph">{item.name[language]}</p>
                <p id="price-paragraph" className="price-paragraph-bigger">
                  {item.price} €
                </p>
                <p id="price-paragraph" className="price-paragraph-smaller">
                  {item.price.split("/")[0]} €
                  <br />
                  {item.price.split("/")[1]} €
                </p>
                <p id="weight-ingredients-paragraph">
                  {item.weight}
                  <br></br>
                  {item.ingredients[language]}
                  <br></br>
                  {item.allergens}
                </p>
              </div>
            ))}
          </div>
        ))}
      {menuItems.length > itemsPerPage && (
        <div className="pagination">
          {[...Array(Math.ceil(menuItems.length / itemsPerPage))].map(
            (_, index) => (
              <button
                key={index}
                onClick={() => {
                  handlePaginationButtonClick();
                  handlePageChange(index);
                }}
                className={index === currentPage ? "active" : ""}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MenuSection;
