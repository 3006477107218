import React from "react";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext.js";

import "../App.css";

const AboutUsSection = () => {
  const { language } = useLanguage();
  const { title, content } = translations[language].aboutUs;

  return (
    <div className="about-us-section">
      <div className="about-us-section-content">
        <div className="about-us-section-text">
          <h1>{title}</h1>
          <p>{content}</p>
        </div>
        <div className="about-us-section-image">
          <img
            loading="lazy"
            src="../images/aboutBanner.png"
            alt="Stazione Pizza Banner"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
