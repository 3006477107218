import React from "react";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext.js";

import "../App.css";

const PrivacyPolicy = () => {
  const { language } = useLanguage();
  const {
    privacyPolicy: { title, content },
  } = translations[language];

  const policyPoints = content
    .split("\n\n")
    .map((point, index) => <li key={index}>{point}</li>);

  return (
    <div className="privacy-policy">
      <header>
        <h1>{title}</h1>
      </header>
      <section>
        <ul>{policyPoints}</ul>
      </section>
      <footer>
        <p>
          &copy; {new Date().getFullYear()} Stazione Pizza.{" "}
          {translations[language].footerSection.content}
        </p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
