import React from "react";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext.js";

import "../App.css";

const LocationSection = () => {
  const { language } = useLanguage();
  const { title, address } = translations[language].location;

  return (
    <div className="location-section">
      <div className="location-section-content">
        <div className="location-section-text">
          <h1>{title}</h1>
          <p>{address}</p>
        </div>
        <div className="map-image">
          <img
            loading="lazy"
            src="../images/map.png"
            alt="Map of the location"
          />
        </div>
      </div>
    </div>
  );
};

export default LocationSection;
