import React, { useRef } from "react";
import MenuBannerSection from "../components/MenuBannerSection";
import MenuSection from "../components/MenuSection";

const Menu = () => {
  const bannerRef = useRef(null);

  return (
    <>
      <MenuBannerSection ref={bannerRef} />
      <MenuSection bannerRef={bannerRef} />
    </>
  );
};

export default Menu;
