import React, { useState, useEffect } from "react";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext.js";

import "../App.css";

const PopUpSection = ({ isOpen, onClose }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { language } = useLanguage();
  const { heading, bistro, bolt, foodora, wolt, close } =
    translations[language].popUpSection;

  useEffect(() => {
    let timeout;
    if (isOpen) {
      timeout = setTimeout(() => {
        setShowPopup(true);
        setIsLoading(false);
      }, 1000);
    } else {
      setShowPopup(false);
      setIsLoading(true);
    }

    return () => clearTimeout(timeout);
  }, [isOpen]);

  useEffect(() => {
    if (showPopup) {
      const images = document.querySelectorAll(".popup-item img");
      images.forEach((image) => {
        image.src = image.getAttribute("data-src");
      });
    }
  }, [showPopup]);

  if (!isOpen && !showPopup) {
    return null;
  }

  return (
    <section
      className={`popup-container ${showPopup ? "show" : ""}`}
      aria-label="Popup Section"
    >
      <div
        className={`popup-content ${showPopup ? "show" : ""}`}
        role="dialog"
        aria-labelledby="popup-heading"
      >
        <div className={`loading-animation ${isLoading ? "show" : ""}`}>
          <div className="loader"></div>
        </div>
        <h1
          id="popup-heading"
          style={{ display: isLoading ? "none" : "block" }}
        >
          {heading}
        </h1>
        <div className={`pop-up-item-container ${showPopup ? "show" : ""}`}>
          {showPopup && (
            <>
              <div className="popup-item">
                <h3>{bistro}</h3>
                <a
                  href="https://www.bistro.sk/restauracia/pizza-stazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    data-src="/images/Just Eat Takeaway.png"
                    alt="Bistro.sk"
                  />
                </a>
              </div>
              <div className="popup-item">
                <h3>{bolt}</h3>
                <a
                  href="https://bolt.eu/sk-sk/food/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    data-src="/images/Bolt Food.png"
                    alt="Bolt Food"
                  />
                </a>
              </div>
              <div className="popup-item">
                <h3>{foodora}</h3>
                <a
                  href="https://www.foodora.sk/restaurant/p7l2/pizza-stazione"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    data-src="/images/Foodora.png"
                    alt="Foodora"
                  />
                </a>
              </div>
              <div className="popup-item">
                <h3>{wolt}</h3>
                <a
                  href="https://wolt.com/en/svk/kosice/restaurant/stazione-pizza"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    data-src="/images/Wolt Delivery.png"
                    alt="Wolt"
                  />
                </a>
              </div>
            </>
          )}
        </div>
        <button
          onClick={onClose}
          style={{ display: isLoading ? "none" : "block" }}
        >
          {close}
        </button>
      </div>
    </section>
  );
};

export default PopUpSection;
