import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useLanguage } from "./LanguageContext";
import translations from "./Translations.js";

import Home from "./pages/Home";
import Menu from "./pages/Menu";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import "./App.css";
import "font-awesome/css/font-awesome.min.css";

function scrollToTop() {
  window.scrollTo(0, 0);
}

function App() {
  const { language, changeLanguage } = useLanguage();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {
    showMenu,
    enLang,
    skLang,
    esLang,
    czLang,
    uaLang,
    ruLang,
    stazioneButton,
  } = translations[language].appJS;

  const { title, home, menu, privacyPolicy, content, subtitle, address } =
    translations[language].footerSection;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <nav className="navbar">
        <div className="hamburger" onClick={toggleMenu}>
          <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`bar ${isMenuOpen ? "open" : ""}`}></div>
        </div>
        <ul className={`nav-list ${isMenuOpen ? "open" : ""}`}>
          <li className="nav-item left">
            <Link to="/home">{stazioneButton}</Link>
          </li>
          <li className="nav-item right">
            <a
              href="https://www.instagram.com/stazionepizza/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../images/ig.png" alt="Instagram profile" />
            </a>
          </li>
          <li className="nav-item right">
            <a
              href="https://www.facebook.com/profile.php?id=100065635742860"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../images/fb.png" alt="Facebook page" />
            </a>
          </li>
          <li className="nav-item right">
            <select
              className="language-switcher"
              value={language}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <option value="en">{enLang}</option>
              <option value="sk">{skLang}</option>
              <option value="es">{esLang}</option>
              <option value="cz">{czLang}</option>
              <option value="ua">{uaLang}</option>
              <option value="ru">{ruLang}</option>
            </select>
          </li>
          <li className="nav-item right">
            <Link id="menuBtn" to="/menu">
              <button>{showMenu}</button>
            </Link>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>

      <div className="footer-section">
        <div className="footer-section-information">
          <h1>Stazione pizza</h1>
          <div className="footer-contact">
            <a href="https://www.example.com">
              <i className="fa fa-map"></i>
              {address}
            </a>
            <a href="tel:+421911750250">
              <i className="fa fa-phone"></i>+421 911 750 250
            </a>
            <a href="mailto:support@stazionepizza.sk">
              <i className="fa fa-envelope"></i>support@stazionepizza.sk
            </a>
            <a href="https://stazionepizza.sk">
              <i className="fa fa-globe"></i>https://stazionepizza.sk
            </a>
          </div>
        </div>
        <div className="footer-section-links">
          <h1>{title}</h1>
          <div className="footer-links">
            <Link to="/home" onClick={scrollToTop}>
              <i className="fa fa-check"></i>
              {home}
            </Link>
            <Link to="/menu" onClick={scrollToTop}>
              <i className="fa fa-check"></i>
              {menu}
            </Link>
            <Link to="/privacy-policy" onClick={scrollToTop}>
              <i className="fa fa-check"></i>
              {privacyPolicy}
            </Link>
            <div className="footer-section-socials">
              <a href="https://www.instagram.com">
                <img src="../images/ig.png" alt="Instagram profile" />
              </a>
              <a href="https://www.facebook.com">
                <img src="../images/fb.png" alt="Facebook page" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-section-cookies">
          <p>
            {content} <Link to="/privacy-policy">{subtitle}</Link>
          </p>
        </div>
      </div>
    </Router>
  );
}

export default App;
