import React, { useState, useEffect } from "react";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext.js";

import "../App.css";

const OrderOnlineHomeSection = () => {
  const [workImages, setWorkImages] = useState([]);

  const { language } = useLanguage();
  const { title } = translations[language].orderOnline;

  useEffect(() => {
    fetch("Api.json")
      .then((response) => response.json())
      .then((data) => {
        const workData = data.work[language] || [];
        setWorkImages(workData);
      })
      .catch((error) => {
        console.error("Error fetching work image data:", error);
      });
  }, [language]);

  return (
    <div className="order-online-section-home">
      <h1>{title}</h1>
      <div className="order-online-grid">
        {workImages.map((image, index) => (
          <div className="order-online-section-home-block" key={index}>
            <img loading="lazy" src={image.path} alt={image.name} />
            <p>{image.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderOnlineHomeSection;
