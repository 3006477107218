import React, { useState, useEffect } from "react";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext.js";

import "../App.css";

const GallerySection = () => {
  const [pizzaImages, setPizzaImages] = useState([]);
  const { language } = useLanguage();
  const { title } = translations[language].gallery;

  useEffect(() => {
    fetch("Api.json")
      .then((response) => response.json())
      .then((data) => {
        const pizzaData = data.pizzas;
        setPizzaImages(pizzaData);
      })
      .catch((error) => {
        console.error("Error fetching pizza image data:", error);
      });
  }, []);

  return (
    <div className="gallery-section">
      <h1>{title}</h1>
      <div className="image-grid">
        {pizzaImages.map((image, index) => (
          <div className="image-container" key={index}>
            <img loading="lazy" src={image.path} alt={image.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GallerySection;
