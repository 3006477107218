import React from "react";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext.js";

import "../App.css";

const GuestFeedbackSection = () => {
  const { language } = useLanguage();
  const { feedback, signature } = translations[language].guestFeedback;

  return (
    <div className="guest-feedback-section">
      <p className="guest-feedback-paragraph">{feedback}</p>
      <p className="guest-signature-paragraph">{signature}</p>
    </div>
  );
};

export default GuestFeedbackSection;
