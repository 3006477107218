const translations = {
  sk: {
    appJS: {
      showMenu: "Zobraziť menu",
      enLang: "EN",
      skLang: "SK",
      esLang: "ES",
      czLang: "CZ",
      uaLang: "УА",
      ruLang: "РУ",
      stazioneButton: "Stazione pizza",
    },
    privacyPolicy: {
      title: "Zásady ochrany súkromia",
      content:
        "Vaše súkromie je pre nás dôležité. Táto stránka zhromažďuje niektoré informácie, aby sme vám mohli poskytnúť lepšie služby. Prečítajte si naše zásady ochrany súkromia a pravidlá pre súbory cookies nižšie:\n\n" +
        "1. Zbieranie informácií: Zhromažďujeme osobné informácie iba v prípade, že ich dobrovoľne poskytnete, napríklad pri objednávke pizze. K tomu patrí meno, adresa a kontaktné údaje.\n\n" +
        "2. Súbory cookie: Používame súbory cookie na zlepšenie funkčnosti našej stránky. Tieto súbory nám pomáhajú analyzovať návštevnosť a poskytovať personalizovaný obsah. Ak nesúhlasíte s používaním súborov cookie, môžete ich vypnúť v nastaveniach vášho prehliadača.\n\n" +
        "3. Zdieľanie informácií: Vaše osobné údaje nezdieľame s tretími stranami bez vášho súhlasu. Súbor cookie informácie môžeme zdieľať s našimi analytickými partnermi pre zlepšenie služieb.\n\n" +
        "4. Bezpečnosť údajov: Vaše údaje sú u nás v bezpečí. Používame moderné bezpečnostné opatrenia na ochranu pred neoprávneným prístupom alebo zneužitím.\n\n" +
        "Ďakujeme, že ste si prečítali naše zásady ochrany súkromia.",
    },
    menuBanner: {
      title: "Vitajte v Stazione Pizza!",
      subtitle:
        "Ponúkame lahodné autentické talianske pizze vyrobené z najčerstvejších surovín, všetko podávané v útulnej atmosfére.",
      orderButton: "Objednať teraz",
    },
    menuSection: {
      paginationButton: "Zavrieť",
      priceLabels: {
        currency: "EUR",
        split: ["before", "after"],
      },
      Pizza: "pizza",
      Snacks: "snacks",
      Alcohol: "alkohol",
      Wine: "víno",
      Beer: "pivo",
      NonAlcoholic: "nealkoholické",
    },
    popUpSection: {
      heading: "Objednať teraz",
      bistro: "Bistro.sk",
      bolt: "Bolt Food",
      foodora: "Foodora",
      wolt: "Wolt",
      close: "Zavrieť",
    },
    aboutUs: {
      title: "O nás",
      content:
        "Vitajte v Stazione pizza, konečnej destinácii pre milovníkov pizze v Košiciach, na Slovensku. S vášňou pre autentické talianske chute a záväzkom kvalite sa snažíme vytvoriť dokonalý zážitok s pizzou pre našich zákazníkov.",
    },
    welcomeBanner: {
      title: "Zažite autentickú taliansku pizzu",
      subtitle:
        "Pochutnajte si na lahodných chute našich ručne vyrábaných pizz, vyrobených z najlepších surovín.",
      orderButton: "Objednať teraz",
    },
    gallery: {
      title: "Galéria",
    },
    guestFeedback: {
      feedback:
        "Stazione pizza je ideálne miesto pre milovníkov pizze! Kôrka je chrumkavá a ingrediencie sú čerstvé a lahodné. Útulná atmosféra a priateľský personál to robia ešte lepším. Ďakujeme za úžasný zážitok!",
      signature: "-Sophie",
    },
    location: {
      title: "Lokalita",
      address: "Staničné námestie 1458/9, 040 01 Košice",
    },
    orderOnline: {
      title: "Objednať online",
    },
    footerSection: {
      title: "Užitočné odkazy",
      home: "Domov",
      menu: "Menu",
      privacyPolicy: "Zásady ochrany súkromia",
      content:
        "Používame cookies, aby sme vám zaistili čo najlepšie skúsenosti na našej webovej stránke.",
      subtitle: "Dozvedieť sa viac",
      address: "Staničné námestie 9, Košice",
    },
  },
  en: {
    appJS: {
      showMenu: "Show menu",
      enLang: "EN",
      skLang: "SK",
      esLang: "ES",
      czLang: "CZ",
      uaLang: "УА",
      ruLang: "РУ",
      stazioneButton: "Stazione pizza",
    },
    privacyPolicy: {
      title: "Privacy Policy",
      content:
        "Your privacy is important to us. This website collects some information to provide you with better services. Please read our privacy policy and cookie rules below:\n\n" +
        "1. Information Collection: We collect personal information only if you voluntarily provide it, such as when ordering pizza. This includes your name, address, and contact details.\n\n" +
        "2. Cookie Files: We use cookie files to enhance the functionality of our website. These files help us analyze traffic and provide personalized content. If you do not agree with the use of cookie files, you can disable them in your browser settings.\n\n" +
        "3. Sharing Information: We do not share your personal data with third parties without your consent. Cookie file information may be shared with our analytics partners to improve our services.\n\n" +
        "4. Data Security: Your data is safe with us. We employ modern security measures to protect against unauthorized access or misuse.\n\n" +
        "Thank you for reading our privacy policy.",
    },
    menuBanner: {
      title: "Welcome to Stazione Pizza!",
      subtitle:
        "We offer delicious authentic Italian pizzas made with the freshest ingredients, all served in a cozy atmosphere.",
      orderButton: "Order now",
    },
    menuSection: {
      paginationButton: "Close",
      priceLabels: {
        currency: "USD",
        split: ["before", "after"],
      },
      Pizza: "pizza",
      Snacks: "snacks",
      Alcohol: "alcohol",
      Wine: "wine",
      Beer: "beer",
      NonAlcoholic: "non-Alcohol",
    },
    popUpSection: {
      heading: "Order Now",
      bistro: "Bistro.sk",
      bolt: "Bolt Food",
      foodora: "Foodora",
      wolt: "Wolt",
      close: "Close",
    },
    aboutUs: {
      title: "About us",
      content:
        "Welcome to Stazione pizza, the ultimate destination for pizza lovers in Košice, Slovakia. With passion for authentic Italian flavors and a commitment to quality, we strive to create the perfect pizza experience for our customers.",
    },
    welcomeBanner: {
      title: "Experience Authentic Italian Pizza",
      subtitle:
        "Indulge in the mouth-watering flavors of our handcrafted pizzas made with the finest ingredients.",
      orderButton: "Order now",
    },
    gallery: {
      title: "Gallery",
    },
    guestFeedback: {
      feedback:
        "Stazione pizza is the perfect place for pizza lovers! The crust is crispy and the toppings are fresh and delicious. The cozy atmosphere and friendly staff make it even better. Thank you for the amazing experience!",
      signature: "-Sophie",
    },
    location: {
      title: "Location",
      address: "Staničné námestie 1458/9, 040 01 Košice",
    },
    orderOnline: {
      title: "Order online",
    },
    footerSection: {
      title: "Useful links",
      home: "Home",
      menu: "Menu",
      privacyPolicy: "Privacy policy",
      content:
        "We use cookies to ensure you get the best experience on our website.",
      subtitle: "Learn more",
      address: "Staničné námestie 9, Košice",
    },
  },
  es: {
    appJS: {
      showMenu: "Mostrar menú",
      enLang: "EN",
      skLang: "SK",
      esLang: "ES",
      czLang: "CZ",
      uaLang: "УА",
      ruLang: "РУ",
      stazioneButton: "Stazione pizza",
    },
    privacyPolicy: {
      title: "Política de privacidad",
      content:
        "Su privacidad es importante para nosotros. Esta página web recopila cierta información para proporcionarle mejores servicios. Lea nuestras políticas de privacidad y reglas de cookies a continuación:\n\n" +
        "1. Recopilación de información: Recopilamos información personal solo si la proporciona voluntariamente, como al ordenar pizza. Esto incluye su nombre, dirección y detalles de contacto.\n\n" +
        "2. Archivos de cookies: Utilizamos archivos de cookies para mejorar la funcionalidad de nuestro sitio web. Estos archivos nos ayudan a analizar el tráfico y proporcionar contenido personalizado. Si no está de acuerdo con el uso de archivos de cookies, puede desactivarlos en la configuración de su navegador.\n\n" +
        "3. Compartir información: No compartimos sus datos personales con terceros sin su consentimiento. La información de los archivos de cookies puede compartirse con nuestros socios de análisis para mejorar nuestros servicios.\n\n" +
        "4. Seguridad de datos: Sus datos están seguros con nosotros. Empleamos medidas de seguridad modernas para proteger contra el acceso no autorizado o el mal uso.\n\n" +
        "Gracias por leer nuestra política de privacidad.",
    },
    menuBanner: {
      title: "¡Bienvenido a Stazione Pizza!",
      subtitle:
        "Ofrecemos deliciosas pizzas auténticas italianas elaboradas con los ingredientes más frescos, todo servido en un ambiente acogedor.",
      orderButton: "Ordenar ahora",
    },
    menuSection: {
      paginationButton: "Cerrar",
      priceLabels: {
        currency: "EUR",
        split: ["before", "after"],
      },
      Pizza: "pizza",
      Snacks: "bocadillos",
      Alcohol: "alcohol",
      Wine: "vino",
      Beer: "cerveza",
      NonAlcoholic: "sin alcohol",
    },
    popUpSection: {
      heading: "Ordenar ahora",
      bistro: "Bistro.sk",
      bolt: "Bolt Food",
      foodora: "Foodora",
      wolt: "Wolt",
      close: "Cerrar",
    },
    aboutUs: {
      title: "Sobre nosotros",
      content:
        "Bienvenido a Stazione pizza, el destino definitivo para los amantes de la pizza en Košice, Eslovaquia. Con pasión por los auténticos sabores italianos y un compromiso con la calidad, nos esforzamos por crear la experiencia perfecta de pizza para nuestros clientes.",
    },
    welcomeBanner: {
      title: "Experimenta la auténtica pizza italiana",
      subtitle:
        "Déjate llevar por los sabores irresistibles de nuestras pizzas artesanales elaboradas con los mejores ingredientes.",
      orderButton: "Ordenar ahora",
    },
    gallery: {
      title: "Galería",
    },
    guestFeedback: {
      feedback:
        "Stazione pizza es el lugar perfecto para los amantes de la pizza. ¡La masa está crujiente y los ingredientes son frescos y deliciosos! La atmósfera acogedora y el personal amable lo hacen aún mejor. ¡Gracias por la increíble experiencia!",
      signature: "-Sophie",
    },
    location: {
      title: "Ubicación",
      address: "Staničné námestie 1458/9, 040 01 Košice",
    },
    orderOnline: {
      title: "Ordenar en línea",
    },
    footerSection: {
      title: "Enlaces útiles",
      home: "Inicio",
      menu: "Menú",
      privacyPolicy: "Política de privacidad",
      content:
        "Utilizamos cookies para asegurarnos de brindarle la mejor experiencia en nuestro sitio web.",
      subtitle: "Aprende más",
      address: "Staničné námestie 9, Košice",
    },
  },
  cz: {
    appJS: {
      showMenu: "Zobrazit menu",
      enLang: "EN",
      skLang: "SK",
      esLang: "ES",
      czLang: "CZ",
      uaLang: "УА",
      ruLang: "РУ",
      stazioneButton: "Stazione pizza",
    },
    privacyPolicy: {
      title: "Zásady ochrany soukromí",
      content:
        "Vaše soukromí je pro nás důležité. Tato webová stránka shromažďuje některé informace, aby Vám mohla poskytnout lepší služby. Přečtěte si níže naše zásady ochrany soukromí a pravidla pro používání souborů cookie:\n\n" +
        "1. Shromažďování informací: Shromažďujeme osobní údaje pouze tehdy, pokud je dobrovolně poskytnete, například při objednávání pizzy. K tomu může patřit Vaše jméno, adresa a kontaktní údaje.\n\n" +
        "2. Soubory cookie: Používáme soubory cookie ke zlepšení funkčnosti našich webových stránek. Tyto soubory nám pomáhají analyzovat provoz a poskytovat personalizovaný obsah. Pokud nesouhlasíte s používáním souborů cookie, můžete je zakázat ve svých nastaveních prohlížeče.\n\n" +
        "3. Sdílení informací: Vaše osobní údaje nebudeme sdílet s třetími stranami bez Vašeho souhlasu. Informace o souborech cookie mohou být sdíleny s našimi partnery v oblasti analýz k vylepšení našich služeb.\n\n" +
        "4. Bezpečnost dat: Vaše údaje jsou u nás v bezpečí. Používáme moderní bezpečnostní opatření k ochraně před neoprávněným přístupem nebo zneužitím.\n\n" +
        "Děkujeme, že jste si přečetli naše zásady ochrany soukromí.",
    },
    menuBanner: {
      title: "Vítejte v Stazione Pizza!",
      subtitle:
        "Nabízíme lahodné autentické italské pizzy vyrobené z nejčerstvějších ingrediencí, vše podávané v útulné atmosféře.",
      orderButton: "Objednat hned",
    },
    menuSection: {
      paginationButton: "Zavřít",
      priceLabels: {
        currency: "CZK",
        split: ["before", "after"],
      },
      Pizza: "pizza",
      Snacks: "snacks",
      Alcohol: "alkohol",
      Wine: "víno",
      Beer: "pivo",
      NonAlcoholic: "nealkoholický",
    },
    popUpSection: {
      heading: "Objednat nyní",
      bistro: "Bistro.sk",
      bolt: "Bolt Food",
      foodora: "Foodora",
      wolt: "Wolt",
      close: "Zavřít",
    },
    aboutUs: {
      title: "O nás",
      content:
        "Vítejte v Stazione pizza, konečné destinaci pro milovníky pizzy v Košicích, na Slovensku. S vášní pro autentické italské chutě a závazkem kvalitě se snažíme vytvořit dokonalý zážitek s pizzou pro naše zákazníky.",
    },
    welcomeBanner: {
      title: "Zažijte autentickou italskou pizzu",
      subtitle:
        "Pochutnejte si na lahodných chutích našich ručně vyráběných pizz, vyrobených z nejkvalitnějších surovin.",
      orderButton: "Objednat nyní",
    },
    gallery: {
      title: "Galerie",
    },
    guestFeedback: {
      feedback:
        "Stazione pizza je ideální místo pro milovníky pizzy! Kůrčička je křupavá a ingredience jsou čerstvé a lahodné. Útulná atmosféra a přátelský personál to dělají ještě lepším. Děkujeme za úžasný zážitek!",
      signature: "-Sophie",
    },
    location: {
      title: "Lokalita",
      address: "Staničné námestie 1458/9, 040 01 Košice",
    },
    orderOnline: {
      title: "Objednat online",
    },
    footerSection: {
      title: "Užitečné odkazy",
      home: "Domů",
      menu: "Menu",
      privacyPolicy: "Zásady ochrany soukromí",
      content:
        "Používáme cookies, abychom vám zajistili co nejlepší zážitek z našich webových stránek.",
      subtitle: "Dozvědět se více",
      address: "Staničné námestie 9, Košice",
    },
  },
  ua: {
    appJS: {
      showMenu: "Показати меню",
      enLang: "EN",
      skLang: "SK",
      esLang: "ES",
      czLang: "CZ",
      uaLang: "УА",
      ruLang: "РУ",
      stazioneButton: "Stazione pizza",
    },
    privacyPolicy: {
      title: "Політика конфіденційності",
      content:
        "Ваша конфіденційність для нас важлива. Цей веб-сайт збирає деяку інформацію, щоб ми могли надавати вам кращі послуги. Прочитайте наші правила конфіденційності та правила щодо файлів cookie нижче:\n\n" +
        "1. Збір інформації: Ми збираємо особисту інформацію тільки у випадку, якщо ви надаєте їх добровільно, наприклад, при замовленні піци. До цього входять ім'я, адреса та контактні дані.\n\n" +
        "2. Файли cookie: Ми використовуємо файли cookie для поліпшення функціональності нашого веб-сайту. Ці файли допомагають нам аналізувати відвідуваність та надавати персоналізований вміст. Якщо ви не згодні з використанням файлів cookie, ви можете вимкнути їх у налаштуваннях вашого браузера.\n\n" +
        "3. Обмін інформацією: Ми не розголошуємо ваші особисті дані третім сторонам без вашої згоди. Інформацію з файлів cookie ми можемо обмінювати з нашими аналітичними партнерами для покращення послуг.\n\n" +
        "4. Безпека даних: Ваші дані у нас в безпеці. Ми використовуємо сучасні заходи безпеки для захисту від несанкціонованого доступу або зловживання.\n\n" +
        "Дякуємо, що прочитали наші правила конфіденційності.",
    },
    menuBanner: {
      title: "Ласкаво просимо в Stazione Pizza!",
      subtitle:
        "Ми пропонуємо смачні автентичні італійські піци, виготовлені з найсвіжіших інгредієнтів, все подається в затишній атмосфері.",
      orderButton: "Замовити зараз",
    },
    menuSection: {
      paginationButton: "Закрити",
      priceLabels: {
        currency: "Євро",
        split: ["до", "після"],
      },
      Pizza: "піца",
      Snacks: "закуски",
      Alcohol: "алкоголь",
      Wine: "вино",
      Beer: "пиво",
      NonAlcoholic: "безалкогольні",
    },
    popUpSection: {
      heading: "Замовити зараз",
      bistro: "Bistro.sk",
      bolt: "Bolt Food",
      foodora: "Foodora",
      wolt: "Wolt",
      close: "Закрити",
    },
    aboutUs: {
      title: "Про нас",
      content:
        "Ласкаво просимо в Stazione pizza, кінцеву дестинацію для любителів піци в Кошицях, на Словаччині. З пасією до автентичних італійських смаків та зобов'язанням до якості ми намагаємося створити ідеальний досвід з піцою для наших клієнтів.",
    },
    welcomeBanner: {
      title: "Відчуйте справжню італійську піцу",
      subtitle:
        "Спробуйте смачні смаки наших ручно виготовлених піц, зроблених з кращих інгредієнтів.",
      orderButton: "Замовити зараз",
    },
    gallery: {
      title: "Галерея",
    },
    guestFeedback: {
      feedback:
        "Stazione pizza - ідеальне місце для любителів піци! Корж хрумкий, інгредієнти свіжі і смачні. Затишна атмосфера і дружелюбний персонал роблять його ще кращим. Дякуємо за чудовий досвід!",
      signature: "-Софі",
    },
    location: {
      title: "Локація",
      address: "Станційне намісто 1458/9, 040 01 Кошице",
    },
    orderOnline: {
      title: "Замовити онлайн",
    },
    footerSection: {
      title: "Корисні посилання",
      home: "Дім",
      menu: "Меню",
      privacyPolicy: "Політика конфіденційності",
      content:
        "Ми використовуємо файли cookie, щоб забезпечити вам найкращі враження на нашому веб-сайті.",
      subtitle: "Дізнатися більше",
      address: "Станічне площа 9, Кошице",
    },
  },
  ru: {
    appJS: {
      showMenu: "Показать меню",
      enLang: "EN",
      skLang: "SK",
      esLang: "ES",
      czLang: "CZ",
      uaLang: "УА",
      ruLang: "РУ",
      stazioneButton: "Stazione pizza",
    },
    privacyPolicy: {
      title: "Политика конфиденциальности",
      content:
        "Ваша конфиденциальность важна для нас. Этот сайт собирает некоторую информацию, чтобы мы могли предоставить вам лучшие услуги. Прочитайте наши правила конфиденциальности и правила для файлов cookie ниже:\n\n" +
        "1. Сбор информации: Мы собираем личную информацию только в том случае, если вы предоставляете ее добровольно, например, при заказе пиццы. К этому относятся имя, адрес и контактные данные.\n\n" +
        "2. Файлы cookie: Мы используем файлы cookie для улучшения функциональности нашего сайта. Эти файлы помогают нам анализировать посещаемость и предоставлять персонализированный контент. Если вы не согласны с использованием файлов cookie, вы можете отключить их в настройках вашего браузера.\n\n" +
        "3. Обмен информацией: Мы не передаем ваши личные данные третьим сторонам без вашего согласия. Информацию из файлов cookie мы можем обменивать с нашими аналитическими партнерами для улучшения услуг.\n\n" +
        "4. Безопасность данных: Ваши данные у нас в безопасности. Мы используем современные средства безопасности для защиты от несанкционированного доступа или злоупотребления.\n\n" +
        "Благодарим вас за то, что прочитали наши правила конфиденциальности.",
    },
    menuBanner: {
      title: "Добро пожаловать в Stazione Pizza!",
      subtitle:
        "Мы предлагаем вкусные подлинные итальянские пиццы, приготовленные из свежайших ингредиентов, все подается в уютной атмосфере.",
      orderButton: "Заказать сейчас",
    },
    menuSection: {
      paginationButton: "Закрыть",
      priceLabels: {
        currency: "Евро",
        split: ["до", "после"],
      },
      Pizza: "пицца",
      Snacks: "закуски",
      Alcohol: "алкоголь",
      Wine: "вино",
      Beer: "пиво",
      NonAlcoholic: "безалкогольные",
    },
    popUpSection: {
      heading: "Заказать сейчас",
      bistro: "Bistro.sk",
      bolt: "Bolt Food",
      foodora: "Foodora",
      wolt: "Wolt",
      close: "Закрыть",
    },
    aboutUs: {
      title: "О нас",
      content:
        "Добро пожаловать в Stazione pizza, конечное место для любителей пиццы в Кошице, Словакия. С страстью к подлинным итальянским вкусам и обязательством качеству мы стремимся создать идеальный опыт с пиццей для наших клиентов.",
    },
    welcomeBanner: {
      title: "Почувствуйте настоящую итальянскую пиццу",
      subtitle:
        "Попробуйте вкусные блюда наших ручных пицц, сделанных из лучших ингредиентов.",
      orderButton: "Заказать сейчас",
    },
    gallery: {
      title: "Галерея",
    },
    guestFeedback: {
      feedback:
        "Stazione pizza - идеальное место для любителей пиццы! Корка хрустящая, ингредиенты свежие и вкусные. Уютная атмосфера и дружелюбный персонал делают его еще лучше. Спасибо за замечательный опыт!",
      signature: "-Софи",
    },
    location: {
      title: "Локация",
      address: "Станционное наместие 1458/9, 040 01 Кошице",
    },
    orderOnline: {
      title: "Заказать онлайн",
    },
    footerSection: {
      title: "Полезные ссылки",
      home: "Домой",
      menu: "Меню",
      privacyPolicy: "Политика конфиденциальности",
      content:
        "Мы используем файлы cookie, чтобы обеспечить вам наилучшие впечатления на нашем веб-сайте.",
      subtitle: "Узнать больше",
      address: "Станционная площа 9, Кошице",
    },
  },
};

export default translations;
