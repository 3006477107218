import React, { useState } from "react";
import PopUpSection from "./PopUpSection";
import translations from "../Translations.js";
import { useLanguage } from "../LanguageContext";

import "../App.css";

const WelcomeBannerSection = () => {
  const { language } = useLanguage();
  const { title, subtitle, orderButton } = translations[language].welcomeBanner;

  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="welcome-banner-section">
      <div className="welcome-banner-content">
        <h1 className="animate__animated animate__fadeInUp">{title}</h1>
        <p className="animate__animated animate__fadeInUp">{subtitle}</p>
        <button
          onClick={openPopup}
          className="animate__animated animate__fadeInUp"
        >
          {orderButton}
        </button>
      </div>

      <PopUpSection
        className="popup"
        isOpen={isPopupOpen}
        onClose={closePopup}
      />
    </div>
  );
};

export default WelcomeBannerSection;
