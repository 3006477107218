import React from "react";
import AboutUsSection from "../components/AboutUsSection";
import WelcomeBannerSection from "../components/WelcomeBannerSection";
import GallerySection from "../components/GallerySection";
import OrderOnlineHomeSection from "../components/OrderOnlineHomeSection";
import LocationSection from "../components/LocationSection";
import GuestFeedbackSection from "../components/GuestFeedbackSection";

const Home = () => {
  return (
    <>
      <WelcomeBannerSection />
      <AboutUsSection />
      <GallerySection />
      <GuestFeedbackSection />
      <OrderOnlineHomeSection />
      <LocationSection />
    </>
  );
};

export default Home;
